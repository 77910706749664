<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/question' }">
        <app-i18n code="entities.question.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.question.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.question.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-question-view-toolbar
        v-if="record"
      ></app-question-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.id.label"
          :value="presenter(record, 'id')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.keywords.label"
          :value="presenter(record, 'keywords')"
        ></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.questionType.label"
          :permission="fields.questionType.readPermission"
          :url="fields.questionType.viewUrl"
          :value="presenter(record, 'questionType')"
        ></app-view-item-relation-to-one>

        <app-view-item-relation-to-one
          :label="fields.questionLanguage.label"
          :permission="
            fields.questionLanguage.readPermission
          "
          :url="fields.questionLanguage.viewUrl"
          :value="presenter(record, 'questionLanguage')"
        ></app-view-item-relation-to-one>

        <app-view-item-relation-to-many
          :label="fields.questionGroups.label"
          :permission="fields.questionGroups.readPermission"
          :url="fields.questionGroups.viewUrl"
          :value="presenter(record, 'questionGroups')"
        ></app-view-item-relation-to-many>

        <app-view-item-text
          :label="fields.questionStatus.label"
          :value="presenter(record, 'questionStatus')"
        ></app-view-item-text>

        <app-view-item-relation-to-many
          :label="fields.allowedUsers.label"
          :permission="fields.allowedUsers.readPermission"
          :url="fields.allowedUsers.viewUrl"
          :value="presenter(record, 'allowedUsers')"
        ></app-view-item-relation-to-many>

<!--        <app-view-item-relation-to-many-->
<!--          :label="fields.preVersions.label"-->
<!--          :permission="fields.preVersions.readPermission"-->
<!--          :url="fields.preVersions.viewUrl"-->
<!--          :value="presenter(record, 'preVersions')"-->
<!--        ></app-view-item-relation-to-many>-->

        <app-view-item-text
          :label="fields.data.label"
          :value="presenter(record, 'data')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.answer.label"
          :value="presenter(record, 'answer')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.lockUntil.label"
          :value="presenter(record, 'lockUntil')"
        ></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.createdById.label"
          :permission="fields.createdById.readPermission"
          :url="fields.createdById.viewUrl"
          :value="presenter(record, 'createdById')"
        ></app-view-item-relation-to-one>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import QuestionViewToolbar from '@/modules/question/components/question-view-toolbar.vue';
import { QuestionModel } from '@/modules/question/question-model';

const { fields } = QuestionModel;

export default {
  name: 'app-question-view-page',

  props: ['id'],

  components: {
    [QuestionViewToolbar.name]: QuestionViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'question/view/record',
      loading: 'question/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'question/view/doFind',
    }),

    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>
